<template>
  <div>
    <h2 v-if="content?.name?.includes('[community]')" class="elevate-title-4 mx-4 mb-6 c-grey-20 lg:mx-10 lg:mb-8">
      Community
    </h2>
    <div ref="elementRef" v-style:bg="backgroundColor" class="relative grid" @click="handlePromotionClick">
      <div class="relative grid-area-stack">
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="full"
        />
        <base-video
          v-else-if="responsiveMedia.video"
          v-bind="responsiveMedia.video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          muted
          class="full cover"
        />
        <div class="absolute-0" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #000000 100%);" />
      </div>
      <div class="relative mt-a w-full px-4 pb-10 grid-area-stack lg:px-10">
        <h2 v-if="title" class="elevate-title-2 c-white" data-test-id="cms-banner-title">
          {{ title }}
        </h2>
        <p v-if="subtitle" class="elevate-body-3 mt-4 c-white" data-test-id="cms-banner-text">
          {{ subtitle }}
        </p>
        <div
          v-if="linkType !== 'No-CTA' && targets.length"
          class="gap-4 wrap i-flex not-first:mt-6"
          :class="equalTargetSizeClass"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { BannerImageContent } from '#types/components/cms/banner-image'

const { content } = defineProps<{
  content: BannerImageContent
}>()

const {
  backgroundColor,
  equalTargetSize,
  linkType,
  media,
  promotionTracking,
  subtitle,
  targets = [],
  title
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const videoRef = ref()
const responsiveMedia = getMedia(media)

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid md:auto-rows-fr md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => videoRef.value?.play())
</script>
